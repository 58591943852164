import { Navbar, Nav, Container } from "react-bootstrap";
import pic from "./images/annie_camping.jpg";

function Navigation() {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={pic}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt=""
          />{" "}
          Annie Conover
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="https://www.linkedin.com/in/anne-conover/">
              LinkedIn
            </Nav.Link>
            <Nav.Link href="https://github.com/anneconover">GitHub</Nav.Link>
            <Nav.Link href="https://members.onepeloton.com/members/anniecon/overview">
              Peloton
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
