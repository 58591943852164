import pic from "./images/annie_camping.jpg";
import "./FlipCard.css";

function FlipCard() {
  return (
    <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img src={pic} alt="logo" width="200px" height="200px" />
        </div>
        <div class="flip-card-back">
          <h1>nice!</h1>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
