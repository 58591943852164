import "./App.css";
import FlipCard from "./FlipCard";
import Navigation from "./Nav";

function App() {
  return (
    <div>
      <Navigation />
      <div className="App">
        <header className="App-header">
          <FlipCard />
          <p>Senior Software Engineer @ Peloton</p>
        </header>
      </div>
    </div>
  );
}

export default App;
